<template>
  <div id="app">
<!--    <div class="nav">-->
<!--      <router-link to="/daFengChe" class="nav-link" active-class="active-link">大风车信息群</router-link>-->
<!--      <router-link to="/cheShangJi" class="nav-link" active-class="active-link">车商记备注</router-link>-->
<!--    </div>-->
    <div class="nav">
      <button @click="navigateTo('/daFengChe')" class="nav-link" :class="{ 'active-link': isActive('/daFengChe') }">大风车信息群</button>
      <button @click="navigateTo('/cheShangJi')" class="nav-link" :class="{ 'active-link': isActive('/cheShangJi') }">车商记备注</button>
<!--      <button @click="navigateTo('/trafficViolation')" class="nav-link" :class="{ 'active-link': isActive('/trafficViolation') }">违章查询</button>-->
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',

  methods: {
    navigateTo(route) {
      // 使用replace方法跳转，避免添加到历史记录
      this.$router.replace(route);
    },
    isActive(route) {
      return this.$route.path === route;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #1989fa;
  margin-top: 10px;
}

.nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}

.nav-link {
  padding: 10px 20px;
  text-decoration: none;
  color: #1989fa;
  font-weight: bold;
  border: 1px solid transparent;
  border-radius: 20px;
  transition: all 0.3s ease;
}

.active-link {
  color: white;
  background-color: #1989fa;
}
</style>
