import axios from "axios";

// 接口的基础 URL 和 AppCode
const BASE_URL = "https://weizhang.shumaidata.com/weizhang";
const APPCODE = "60fe0332505049d8ba57c9ed350622aa";

// 错误码提示信息
const ERROR_MESSAGES = {
  200: "请求成功，数据已返回。",
  400: "请求参数有误，请检查后重试。",
  401: "某些参数填写错误，请核对并重试。",
  404: "请求的资源未找到，请确认接口地址正确。",
  500: "系统发生内部错误，请稍后再试或联系服务商。",
  501: "外部服务暂时不可用，请稍后重试。",
  604: "此接口已停用，请联系服务商了解详情。",
  1001: "信息填写有误，请检查输入后再试。",
  1002: "该城市服务正在维护，请稍后重试。",
  1003: "该城市服务尚未开通，请选择其他城市。",
};

/**
 * 获取违章信息
 * @param {Object} params - 查询参数，包括车牌号等信息
 * @returns {Promise<Object>} - 返回包含违章信息的响应数据
 */
export async function fetchWeizhangData(params) {
  try {
    const response = await axios.get(BASE_URL, {
      headers: {
        Authorization: `APPCODE ${APPCODE}`,
      },
      params,
    });

    const { code, msg, data } = response.data;

    // 根据错误码提供友好的提示信息
    if (code !== 200) {
      const errorMessage = ERROR_MESSAGES[code] || "未知错误，请稍后再试。";
      throw new Error(`错误码：${code}，错误信息：${errorMessage}`);
    }

    return { success: true, data, message: msg };
  } catch (error) {
    console.error("API调用失败：", error);

    // 返回友好的错误信息
    return {
      success: false,
      message: error.message || "请求失败，请稍后再试。",
    };
  }
}