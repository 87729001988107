import VueRouter from 'vue-router'

import DaFengChe from '@/pages/DaFengChe.vue'
import CheShangJi from '@/pages/CheShangJi.vue'
import TrafficViolation from '@/pages/TrafficViolation.vue'


export default new VueRouter({
    routes: [
        {
            path:'/',
            redirect:'/daFengChe'
        },
        {
            path: '/daFengChe',
            component: DaFengChe,
        },

        {
            path: '/cheShangJi',
            component: CheShangJi,
        },
        {
            path: '/trafficViolation',
            component: TrafficViolation,
        }



    ]
})