<template>
  <div>
    <h1>违章查询</h1>
    <form @submit.prevent="submitForm">
      <div>
        <label for="carno">车牌号：</label>
        <input
            id="carno"
            v-model="carno"
            placeholder="请输入车牌号"
            required
        />
      </div>
      <div>
        <label for="cartype">车辆类型：</label>
        <input
            id="cartype"
            v-model="cartype"
            placeholder="请输入车辆类型"
            required
        />
      </div>
      <div>
        <label for="engineno">发动机号：</label>
        <input
            id="engineno"
            v-model="engineno"
            placeholder="请输入发动机号后6位"
            required
        />
      </div>
      <div>
        <label for="frameno">车架号：</label>
        <input
            id="frameno"
            v-model="frameno"
            placeholder="请输入车架号后6位"
            required
        />
      </div>
      <button type="submit" :disabled="loading">
        {{ loading ? "查询中..." : "查询" }}
      </button>
    </form>

    <!-- 查询结果展示 -->
    <div v-if="response">
      <h2>查询结果：</h2>
      <div v-if="response.success">
        <p>违章条数：{{ response.data.vio_total }}</p>
        <p>罚款合计：{{ response.data.find_total }}</p>
        <p>扣分合计：{{ response.data.score_total }}</p>
        <div v-if="response.data.details.length">
          <h3>违章详情：</h3>
          <ul>
            <li v-for="(detail, index) in response.data.details" :key="index">
              <p>时间：{{ detail.vio_time }}</p>
              <p>地址：{{ detail.vio_address }}</p>
              <p>行为：{{ detail.vio_action }}</p>
              <p>罚款：{{ detail.vio_fine }} 元</p>
              <p>扣分：{{ detail.vio_score }} 分</p>
            </li>
          </ul>
        </div>
        <div v-else>
          <p>没有违章记录。</p>
        </div>
      </div>
      <div v-else>
        <p class="error">查询失败：{{ response.message }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import {fetchWeizhangData} from "@/api";

export default {
  name: "TrafficViolation",
  data() {
    return {
      // 车牌号
      carno: "川A803XH",
      // 车辆类型
      cartype: "02",
      // 发动机号
      // engineno: "4847E538",
      engineno: "4847E538",
      // 车架号
      // frameno: "LBV2W3109KMJ54934",
      frameno: "LBV2W3109KMJ54934",

      // 查询结果
      response: null,
      // 加载状态
      loading: false,
    };
  },
  methods: {
    async submitForm() {
      const params = {
        carno: this.carno.trim(),
        cartype: this.cartype.trim(),
        engineno: this.engineno.trim(),
        frameno: this.frameno.trim(),
      };

      // 表单校验
      if (!params.carno || !params.cartype || !params.engineno || !params.frameno) {
        alert("请填写完整的表单信息！");
        return;
      }

      this.loading = true; // 开启加载状态
      try {
        // 调用 API 并保存结果
        this.response = await fetchWeizhangData(params);
        if (!this.response.success) {
          alert(this.response.message);
        }
        // this.response = {
        //   "msg": "成功",
        //   "success": true,
        //   "code": 200,
        //   "data": {
        //     "order_no": "575374049753042944",//订单号
        //     "car_no": "鄂A*****",//车牌号
        //     "vio_total": "1",//违章条数
        //     "find_total": "200",//违章罚款合计
        //     "score_total": "0",//违章扣分合计
        //     "details": [//违章详情，没有违章则为空
        //       {
        //         "vio_time": "2019-04-03 10:30:31",//违章时间
        //         "vio_address": "古田三路四医院分院门前",//违章地址
        //         "vio_action": "机动车违反禁止停车标志、禁止停车标线",//违章行为
        //         "vio_fine": "200",//违章罚款
        //         "vio_score": "0",
        //         "vio_city_name": "武汉",
        //         "vio_city": "4201",//违章所在城市代码
        //         "vio_code": "10393",//违法代码
        //         "col_organ": "武汉市公安局硚口区交通大队",//采集机构
        //         "decision_num": null,//决定书编号
        //         "monitor_num": null,//监控编号
        //         "handle_sign": null,//处理标记，1-未处理 未缴费 2-已处理 未缴费 3-已处理 已缴费。为空或不存在值时，默认为1-未处理 未缴费。
        //         "handle_num": "51fd5edbb00e6a8c7f5f4abae1d764d2",//在线处理编号，该字段可忽略
        //         "online_handle": "1"//在线处理标识，该字段可忽略
        //       }
        //     ]
        //   }
        // }
      } catch (error) {
        alert("查询失败，请稍后重试！");
        console.error(error);
      } finally {
        this.loading = false; // 关闭加载状态
      }
    },
  },
};
</script>

<style scoped>
/* 表单样式 */
form {
  margin-bottom: 20px;
}

form div {
  margin-bottom: 10px;
}

label {
  margin-right: 10px;
  font-weight: bold;
}

input {
  padding: 5px;
  width: 300px;
  max-width: 100%;
  box-sizing: border-box;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* 错误信息样式 */
.error {
  color: red;
  font-weight: bold;
}
</style>